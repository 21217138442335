import React, { useState, useEffect } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import ReactDOMServer from "react-dom/server";

import { DataTable } from "../../../components/tables";
import { CertificateDataGridCols } from "./CertificateDataGridCols";
import { useNavigate } from "react-router-dom";
import { Header } from "../../../components/user-dashboard/header";
import { Sidebar } from "../../../components/user-dashboard/sidebar";
import { AdminService } from "../../../app/service/admin.service";
import { toast } from "react-toastify";
import moment from "moment";
import { courses } from "../../../config";

const CertificateList = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [date, setDate] = useState("");
  const [selectCourse, setSelectCourse] = useState("");
  const navigate = useNavigate();
  const [usersData, setUsersData] = useState<any>([]);
  const [formData, setFormData] = useState({
    startDate: "",
    endDate: "",
    gender: "",
  });

  const handleCancel = () => {
    setName("");
    setEmail(""); // Reset email
    setPhone(""); // Reset phone
    setDate("");
    setFilteredStudents(usersData); 
  };
  const handleSearch = () => {
    const filteredData = usersData.filter((student: any) => {
      const nameMatch =
        student.firstName.toLowerCase().includes(name.toLowerCase()) ||
        student.lastName.toLowerCase().includes(name.toLowerCase());

      const emailMatch = email
        ? student.email.toLowerCase().includes(email.toLowerCase())
        : true;

      const phoneMatch = phone ? student.phone.includes(phone) : true;

      const dateMatch = date
        ? moment(student.createdAt).format("YYYY-MM-DD") === date
        : true;

      const courseMatch = selectCourse
        ? student.courseName === selectCourse
        : true;

      return nameMatch && emailMatch && phoneMatch && dateMatch && courseMatch;
    });

    setFilteredStudents(filteredData);
  };
  const handleLmsCreateMeet = () => {
    navigate("/support/createcertificate");
  };

  useEffect(() => {
    getCertificates();
  }, []);

  const getCertificates = () => {
    const data = {
      search: "",
      start: 1,
      count: 10,
    };
    AdminService.getallCertificatesList(data).then((res: any) => {
      if (res.status === "Success") {
        const formattedData = res?.data.map((item: any) => ({
          ...item,
          createdAt: moment(item.createdAt).format("DD MMM YYYY, hh:mm A"),
        }));
        setUsersData(formattedData);
        setFilteredStudents(formattedData)
      } else {
        toast.error(res.message);
      }
    });
  };
  // const generateCertificate = (type: string, data: any) => {
  //   const payload = {
  //     certificateEmail: data.email,
  //     certificateType: type,
  //     certificateUrl: `${type}.pdf`,
  //     userName: data.firstName,
  //     certificatePeriod: "may 2013",
  //     courseName: data?.courseName[0],
  //   };
  //   AdminService.createCertificate(payload).then((res: any) => {
  //     if (res.status === "Success") {
  //       toast.success(res.message);
  //     } else {
  //       toast.error(res.message);
  //     }
  //   });
  // };
  const generateCertificate = async (type: string, data: any) => {
    const { firstName, courseName, createdAt } = data;
    const date = new Date(createdAt).toLocaleDateString();
    try {
      let payload = {
        certificateEmail: data.email,
        certificateType: type,
        certificateUrl: [],
        userName: firstName,
        certificatePeriod: "May 2013",
        courseName: courseName[0],
        startDate: formData.startDate,
        endDate: formData.endDate,
        gender: formData.gender,
      };
      const res = await AdminService.createCertificate(payload);
      if (res.status === "Success") {
        toast.success(res.message);
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      console.error("Error generating certificate:", error);
      toast.error("An error occurred while generating the certificate.");
    } finally {
    }
  };

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <Header />
        </div>
        <div className="row">
          <div className="col-md-2 h-100 px-0">
            <Sidebar />
          </div>
          <div className="col-md-10 content-scroll content-bg px-3 py-3">
            <div className="row">
              <div className="col-md-10 mt-3">
                <label className="form-label d-flex justify-content-start text-start">
                  <b>Certificates List</b>
                </label>
                <div className="d-flex">
                  <input
                    name="User Name"
                    onChange={(e: any) => setName(e.target.value)}
                    className="form-control mt-1 me-2"
                    value={name}
                    placeholder="Enter the User Name"
                  />
                  <input
                    type="email" // Email input
                    name="Email"
                    onChange={(e: any) => setEmail(e.target.value)}
                    className="form-control mt-1 me-2"
                    value={email}
                    placeholder="Enter the Email"
                  />
                  <input
                    type="phone" // Phone input
                    name="Phone"
                    onChange={(e: any) => setPhone(e.target.value)}
                    className="form-control mt-1 me-2"
                    value={phone}
                    placeholder="Enter the Phone"
                  />
                  <input
                    type="date"
                    name="Date"
                    onChange={(e: any) => setDate(e.target.value)}
                    className="form-control mt-1 me-2"
                    value={date}
                  />
                  <select
                    className="form-select mt-1 me-2"
                    value={selectCourse}
                    onChange={(e: any) => setSelectCourse(e.target.value)}
                  >
                    <option>Select Courses</option>
                    {courses.map((ele: any, index) => {
                      return (
                        <option value={ele.name} key={index}>
                          {ele.name}
                        </option>
                      );
                    })}
                  </select>
                  <button
                    className="btn btn-primary rounded px-4 py-1 ms-3"
                    onClick={handleSearch}
                  >
                    Search
                  </button>
                  <button
                    className="btn btn-secondary rounded ms-2 px-4 py-1"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <DataTable
                tableData={filteredStudents}
                TableCols={CertificateDataGridCols}
                generateCertificate={generateCertificate}
                setFormData={setFormData}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CertificateList;
