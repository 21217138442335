import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Header } from "../../../components/user-dashboard/header";
import { Sidebar } from "../../../components/user-dashboard/sidebar";
import { useLocation, useNavigate } from "react-router-dom";
import { AdminService } from "../../../app/service/admin.service";
import Select from "react-select";

const EditPercentage = () => {
  const navigate = useNavigate();
  const [batchData, setBatchData] = useState<any>([]);
  const location = useLocation();
  const { state } = location;

  const [formData, setFormData] = useState<any>({
    percentage: "",
    userBatchName: "",
  });

  useEffect(() => {
    if (state) {
      setFormData({
        percentage: state.percentage || "",
        userBatchName: state.userBatchName || "",
      });
    }
  }, [state]);

  const [errors, setErrors] = useState<any>({});

  const handleInput = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevState:any) => ({
      ...prevState,
      [name]: name === "courseName" ? value.split(",") : value,
    }));
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  useEffect(() => {
    getMeets();
  }, []);

  const getMeets = () => {
    const data = {
      search: "",
      start: 1,
      count: 10,
    };
    AdminService.getAllBatch(data).then((res: any) => {
      if (res.status === "Success") {
        setBatchData(res.data);
      } else {
        toast.error(res.message);
      }
    });
  };

  const validate = () => {
    const newErrors: any = {};

    if (!formData.percentage) {
      newErrors.percentage = "Percentage is required";
    }

    if (!formData.userBatchName) {
      newErrors.userBatchName = "BatchName is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleRegister = (e: any) => {
    e.preventDefault();
   

    if (validate()) {
        formData.batchId=state._id
      AdminService.updatePercentage(formData).then((res) => {
        if (res.status === "Fail") {
          toast.error(res.error);
        } else {
          toast.success(res.message);
          navigate("/admin/percentageList");
        }
      });
    }
  };

  const handleCancel = () => {
    navigate("/admin/percentageList");
  };
  const bOptions = batchData?.map((type: any) => ({
    value: type.batchName,
    label: type.batchName,
  }));

  const handleSelectChange = (selectedOption: any, { name }: any) => {
    setFormData({ ...formData, [name]: selectedOption.value });
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <Header />
      </div>
      <div className="row">
        <div className="col-md-2 h-100 px-0">
          <Sidebar />
        </div>
        <div className="col-md-10 content-scroll content-bg px-3 py-3">
          <h4 style={{ textAlign: "left" }}>
            <b>Edit Percentage</b>
          </h4>
          <form>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group mt-3">
                  <label className="register-font">Percentage *</label>
                  <input
                    type="text"
                    className={`form-control mt-2 ${
                      errors.percentage ? "is-invalid" : ""
                    }`}
                    placeholder="Percentage"
                    name="percentage"
                    value={formData.percentage}
                    onChange={handleInput}
                  />
                  {errors.percentage && (
                    <div className="invalid-feedback">{errors.percentage}</div>
                  )}
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group mt-3">
                  <label className="register-font">Batch Name *</label>
                  <Select
                    options={bOptions}
                    name="userBatchName"
                    placeholder="Select Batch"
                    value={
                      bOptions.find(
                        (option:any) => option.value === formData.userBatchName
                      ) || null
                    }
                    onChange={handleSelectChange}
                  />

                  {errors.userBatchName && (
                    <div className="text-danger">{errors.userBatchName}</div>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-3">
              <button
                type="submit"
                className="btn btn-primary me-2"
                onClick={handleRegister}
              >
                Update
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditPercentage;
