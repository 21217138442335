import React, { useState, useEffect } from "react";
import { Header } from "../../../components/user-dashboard/header";
import { Sidebar } from "../../../components/user-dashboard/sidebar";
import { DataTable } from "../../../components/tables";
import { useNavigate } from "react-router-dom";
import { RegisterDataGridCols } from "./data-grid-cols";
import { AdminService } from "../../../app/service/admin.service";
import { toast } from "react-toastify";
import moment from "moment";
import ReactDOMServer from "react-dom/server";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import signImg from "../../../assets/images/img/sign.png";
import AicteImg from "../../../assets/images/img/aicte.png";
import apscheImg from "../../../assets/images/img/apsche.png";
import isoImg from "../../../assets/images/img/iso.jpg";
import logoImg from "../../../assets/images/img/Logo.png";








const AmbassadorCerificatesList = () => {
    const [name, setName] = useState("");
    const navigate = useNavigate();
    const [registersData, setRegistersData] = useState([]);
    const [filteredRegisters, setFilteredRegisters] = useState([]);


    const handleCancel = () => {
        setName("");
        setFilteredRegisters(registersData);
    };

    const handleSearch = () => {
        const filteredData = registersData.filter((register: any) =>
            register.name.toLowerCase().includes(name.toLowerCase())
        );
        setFilteredRegisters(filteredData);
    };


    useEffect(() => {
        getRegisters();
    }, []);
    const getRegisters = () => {
        const data = {
            search: "",
            start: 1,
            count: 10,
            type: "ambassador",
        };

        AdminService.getAllRegisterList(data).then((res: any) => {
            if (res.status === "Success") {
                if (res.data.result) {
                    const formattedData = res.data.result.map((item: any) => {
                        return {
                            ...item,
                            createdAt: moment(item.createdAt).format("YYYY-MM-DD hh:mm A"),
                        };
                    });
                    setRegistersData(formattedData);
                    setFilteredRegisters(formattedData);
                }
            } else {
                toast.error(res.message);
            }
        });
    };

    const deleteAmbassadorInfo = (userId: string) => {
        const payload = {
            userId: userId,
        };
        AdminService.deleteRegister(payload).then((res: any) => {
            if (res.status === "Success") {
                toast.success(res.message);
                getRegisters();
            } else {
                toast.error(res.message);
            }
        });
    };

    const updateAmb=(item:any)=>{
        const payload = {
            ambId: item?._id,
        };
        AdminService.updateRegisterAmb(payload).then((res: any) => {
            if (res.status === "Success") {
                toast.success(res.message);
                getRegisters();
            } else {
                toast.error(res.message);
            }
        });

    }
    const handleAccess = async (item: any) => {
        let certificateHtml;

        await updateAmb(item)

        let type = "AmbassadorCertificate"


        switch (type) {
            case "AmbassadorCertificate":
                certificateHtml = ReactDOMServer.renderToString(
                    AmbassadorC(item)
                );
                break;
            default:
                certificateHtml = "";
        }

        const container1 = document.createElement("div");
        container1.style.position = "fixed";
        // container1.style.top = "-10000px";
        // container1.style.left = "-8000px";
        // container1.style.width = "100%";
        // container1.style.minHeight = "100%";
        container1.style.padding = "0px";
        // container1.style.margin = "auto";
        container1.innerHTML = certificateHtml;
        document.body.appendChild(container1);

        await new Promise((resolve) => setTimeout(resolve, 1000));

        // Render the HTML content to canvas
        const canvas = await html2canvas(container1, {
            scale: 2, // Higher scale for better resolution
            useCORS: true, // Enable cross-origin images loading
            scrollX: 0,
            scrollY: 0,
            width: container1.scrollWidth,
            height: container1.scrollHeight,
        });

        // Clean up the temporary HTML container immediately
        document.body.removeChild(container1);

        // Convert canvas to image data
        const imgData = canvas.toDataURL("image/png");

        // Initialize jsPDF with custom dimensions
        const pdf = new jsPDF({
            orientation: "landscape",
            unit: "px",
            format: [canvas.width, canvas.height], // Set dimensions to match canvas
        });

        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();

        // Add the image to the PDF
        pdf.addImage(imgData, "PNG", 0, 0, pageWidth, pageHeight);

        // Save the PDF
        pdf.save("AmbassadorCertificate.pdf");
    }


    const editAmbassador=(data:any)=>{        
        navigate("/admin/editAmbassador", { state: data });
    }


    const AmbassadorC = (item: any) => {
        const date = new Date(item.createdAt).toLocaleDateString();
        return (
            <div className="main-section-a">
                <div className="row">
                    <div className="col-md-3"></div>
                    <div className="col-md-8 text-center">
                        <div className="mb-3">
                            <img src={logoImg} width="200px" alt="" />
                        </div>
                        <h1 className="head-1-1 pt-5 mt-4 mb-0">CERTIFICATE</h1>
                        <span className="para-2"><span className="line me-5">---------------------------------</span> Of
                            Ambassadorship <span className="line ms-5">---------------------------------</span></span>
                        <h4 className="head-2-1 pb-2 text-dark pt-3 sign-2">{item?.name}</h4>
                        <p className="op-8 pt-2 ">For outstanding commitment and exceptional service as a Student Ambassador of Eduexpose</p>
                        <p className="pt-2 op-8">We sincerely appreciate your dedication and contributions to promoting upskilling and career growth among your peers.</p>
                        <div className="d-flex justify-content-between pt-5 pe-5">
                            <div>
                                <div>
                                    <img src={signImg} width="150px" alt="" />
                                </div>
                                <div className="sign">
                                    <span className="para-1 mt-2 text-uppercase">Director</span>
                                </div>
                            </div>
                            <div className="mt-4 pt-2">
                                <div className="">
                                    {date}
                                </div>

                                <div className="sign">
                                    <span className="para-1 text-uppercase">Date</span>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center mt-4">
                            <div className="m-3">
                                <img src={AicteImg} width="80px" alt="" />
                            </div>
                            <div className="m-3">
                                <img src={apscheImg} width="80px" alt="" />
                            </div>
                            <div className="m-3">
                                <img src={isoImg} width="80px" alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-1"></div>
                </div>
            </div>
        );
    }

    return (
        <div>
            <div className="container-fluid">
                <div className="row">
                    <Header />
                </div>
                <div className="row">
                    <div className="col-md-2 h-100 px-0">
                        <Sidebar />
                    </div>
                    <div className="col-md-10 content-scroll content-bg px-3 py-3">
                        <div className="row">
                            <div className="col-md-6 mt-3">
                                <label className="form-label d-flex justify-content-start text-start">
                                    <b>Ambassador Data</b>
                                </label>
                                <div className="d-flex">
                                    <input
                                        name="User Name"
                                        onChange={(e: any) => setName(e.target.value)}
                                        className="form-control mt-1 me-2"
                                        value={name}
                                        placeholder="Enter the User Name"
                                    />
                                    <button
                                        className="btn btn-primary rounded px-4 py-1"
                                        onClick={() => handleSearch()}
                                    >
                                        Search
                                    </button>
                                    <button
                                        className="btn btn-secondary rounded ms-2 px-4 py-1"
                                        onClick={handleCancel}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="mt-5">
                            <DataTable
                                tableData={filteredRegisters}
                                TableCols={RegisterDataGridCols}
                                deleteAmbassadorInfo={deleteAmbassadorInfo}
                                handleAccessData={handleAccess}
                                handleEditUser={editAmbassador}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AmbassadorCerificatesList;
