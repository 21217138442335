import React, { useState, useEffect } from "react";
import { Header } from "../../../components/user-dashboard/header";
import { Sidebar } from "../../../components/user-dashboard/sidebar";
import { DataTable } from "../../../components/tables";
import { useNavigate } from "react-router-dom";
import { StudentsDataGridCols } from "./data-grid-cols";
import { AdminService } from "../../../app/service/admin.service";
import { toast } from "react-toastify";
import moment from "moment";
import { courses } from "../../../config";
import Swal from "sweetalert2";
import * as XLSX from "xlsx";

const StudentsList = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState(""); 
  const [phone, setPhone] = useState(""); 
  const [date, setDate] = useState("");
  const [status, setStatus] = useState("");
  const [selectCourse, setSelectCourse] = useState("");
  const [StudentsData, setStudentsData] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState([]); 
  const navigate = useNavigate();
  const [formData, setFormData] = useState<any>({
    userBatchName: "",
  });
  const [batchData, setBatchData] = useState<any>([]);

  const handleCancel = () => {
    setName("");
    setEmail(""); // Reset email
    setPhone(""); // Reset phone
    setDate("");
    setStatus("");
    setFilteredStudents(StudentsData); 
  };

  const handleSearch = () => {
    const filteredData = StudentsData.filter((student: any) => {
      const nameMatch =
        student.firstName.toLowerCase().includes(name.toLowerCase()) ||
        student.lastName.toLowerCase().includes(name.toLowerCase());

      const emailMatch = email
        ? student.email.toLowerCase().includes(email.toLowerCase())
        : true;

      const phoneMatch = phone
        ? student.phone.includes(phone)
        : true;

      const dateMatch = date
        ? moment(student.createdAt).format("YYYY-MM-DD") === date
        : true;

      const statusMatch = status ? student.status === status : true;
      const courseMatch = selectCourse
        ? student.courseName === selectCourse
        : true;

      return nameMatch && emailMatch && phoneMatch && dateMatch && statusMatch && courseMatch;
    });

    setFilteredStudents(filteredData);
  };

  useEffect(() => {
    getRegisters();
  }, []);

  const getRegisters = () => {
    const data = {
      search: "",
      start: 1,
      count: 10,
    };
    AdminService.getAllPaymentList(data).then((res: any) => {
      if (res.status === "Success") {
        const formattedData = res?.data?.result.map((item: any) => ({
          ...item,
          createdAt: moment(item.createdAt).format("DD MMM YYYY, hh:mm A"),
        }));
        setStudentsData(formattedData);
        setFilteredStudents(formattedData); // Initialize filtered data
      } else {
        toast.error(res.message);
      }
    });
  };

  const handleAccessData = (data: any) => {
    const payload = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      courseName: data.courseName,
      phone: data.phone,
      userBatchName: formData.userBatchName,
    };
    AdminService.userAccess(payload).then((res: any) => {
      if (res.status === "Success") {
        toast.success(res.message);
        getRegisters();
      } else {
        toast.error(res.message);
      }
    });
  };

  const handleSelectChange = (selectedOption: any, { name }: any) => {
    setFormData({ ...formData, [name]: selectedOption.value });
  };

  const deletePaymentInfo = (userId: string) => {
    Swal.fire({
      title: "Are you sure you want to delete User",
      text: "Once deleted, you won't be able to recover this entry!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#F5BE18",
      cancelButtonColor: "#000",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        const payload = {
          userId: userId,
        };

        AdminService.deletePayment(payload).then((res: any) => {
          if (res.status === "Success") {
            toast.success(res.message);
            getRegisters();
          } else {
            toast.error(res.message);
          }
        });
      }
    });
  };

  const handleEditMeet = (data: any) => {
    navigate("/admin/editPaymentData", { state: data });
  };

  const exportData = () => {
    const ws = XLSX.utils.json_to_sheet(filteredStudents);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Register Data");

    // Set the file name
    const fileName = "StudentsList.xlsx";

    // Write the Excel file
    XLSX.writeFile(wb, fileName);
  };

  useEffect(() => {
    getMeets();
  }, []);

  const getMeets = () => {
    const data = {
      search: "",
      start: 1,
      count: 10,
    };
    AdminService.getAllBatch(data).then((res: any) => {
      if (res.status === "Success") {
        setBatchData(res.data);
      } else {
        toast.error(res.message);
      }
    });
  };

  const bOptions = batchData?.map((type: any) => ({ value: type.batchName, label: type.batchName }));

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <Header />
        </div>
        <div className="row">
          <div className="col-md-2 h-100 px-0">
            <Sidebar />
          </div>
          <div className="col-md-10 content-scroll content-bg px-3 py-3">
            <div className="row">
              <div className="col-md-10 mt-3">
                <label className="form-label d-flex justify-content-start text-start">
                  <b> Students List</b>
                </label>
                <div className="d-flex">
                  <input
                    name="User Name"
                    onChange={(e: any) => setName(e.target.value)}
                    className="form-control mt-1 me-2"
                    value={name}
                    placeholder="Enter the User Name"
                  />
                  <input
                    type="email" // Email input
                    name="Email"
                    onChange={(e: any) => setEmail(e.target.value)}
                    className="form-control mt-1 me-2"
                    value={email}
                    placeholder="Enter the Email"
                  />
                  <input
                    type="phone" // Phone input
                    name="Phone"
                    onChange={(e: any) => setPhone(e.target.value)}
                    className="form-control mt-1 me-2"
                    value={phone}
                    placeholder="Enter the Phone"
                  />
                  <input
                    type="date"
                    name="Date"
                    onChange={(e: any) => setDate(e.target.value)}
                    className="form-control mt-1 me-2"
                    value={date}
                  />
                  <select
                    className="form-select mt-1 me-2"
                    value={status}
                    onChange={(e: any) => setStatus(e.target.value)}
                  >
                    <option value="">Payment Status</option>
                    <option value="SUCCESS">Success</option>
                    <option value="FAILURE">Failed</option>
                  </select>
                  <select
                    className="form-select mt-1 me-2"
                    value={selectCourse}
                    onChange={(e: any) => setSelectCourse(e.target.value)}
                  >
                    <option>Select Courses</option>
                    {courses.map((ele: any, index) => {
                      return (
                        <option value={ele.name} key={index}>
                          {ele.name}
                        </option>
                      );
                    })}
                  </select>
                  <button
                    className="btn btn-primary rounded px-4 py-1 ms-3"
                    onClick={handleSearch}
                  >
                    Search
                  </button>
                  <button
                    className="btn btn-secondary rounded ms-2 px-4 py-1"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                </div>
              </div>
              <div className="col-md-2 mt-3">
                <div className="text-end">
                  <button className="btn btn-success" onClick={exportData}>
                    Export
                  </button>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <DataTable
                tableData={filteredStudents} // Use filtered data here
                TableCols={StudentsDataGridCols}
                handleAccessData={handleAccessData}
                deletePaymentInfo={deletePaymentInfo}
                handleEditMeet={handleEditMeet}
                bOptions={bOptions}
                handleSelectChange={handleSelectChange}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentsList;
