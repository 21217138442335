import React, { useEffect, useState } from "react";
import { Header } from "../../../components/user-dashboard/header";
import { Sidebar } from "../../../components/user-dashboard/sidebar";
import moment from "moment";
import { UserService } from "../../../app/service/user.service";
import { toast } from "react-toastify";
import certifcateImg from "../../../assets/images/user_cerificates/cerificate_img.jpg";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import ReactDOMServer from "react-dom/server";
import signImg from "../../../assets/images/img/sign.png";


const LOA = () => {
  const [tableData, setTableData] = useState([]);

  const dateFormat = (dateStr: any) => {
    const date = moment(dateStr);
    const formattedDate = date.format("DD/MMM/YYYY");
    return formattedDate;
  };

  useEffect(() => {
    getLoas();
  }, []);

  const getLoas = () => {
    const userdata: any = sessionStorage.getItem("loginData");
    const userRole = JSON.parse(userdata);
    const email = userRole?.email;
    const data = { email: email };

    UserService.loasList(data)
      .then((res) => {
        if (res.status === "Success") {
          setTableData(res.data);
        } else {
          // toast.error(res.message);
        }
      })
      .catch(() => {
        toast.error("An error occurred while fetching data.");
      });
  };
  const handleView = async (item: any) => {
    
    let certificateHtml = ReactDOMServer.renderToString(loaGenerate(item));

    const container = document.createElement("div");
    container.style.top = "0";
    container.style.left = "0";
    container.style.width = "auto";
    container.style.height = "auto";
    // container.style.overflow = "hidden"; 
    container.innerHTML = certificateHtml;
    container.style.position = 'absolute';
    container.style.top = '-9999px';
    document.body.appendChild(container);
    // container.style.visibility = 'hidden';

    const canvas = await html2canvas(container, {
        scale: 2,
        useCORS: true,
        scrollX: 0,
        scrollY: 0,
        width: container.scrollWidth, 
        height: container.scrollHeight,
      
    });

    document.body.removeChild(container);

    const imgData = canvas.toDataURL("image/png");

    const pdf = new jsPDF({
        orientation: "portrait",
        unit: "px",
        format: [canvas.width, canvas.height], 
    });

    const pageHeight = pdf.internal.pageSize.getHeight();
    let remainingHeight = canvas.height;
    let position = 0;

    while (remainingHeight > 0) {
        pdf.addImage(imgData, "PNG", 0, position, canvas.width, pageHeight);

        remainingHeight -= pageHeight;
        position -= pageHeight;

        if (remainingHeight > 0) {
            pdf.addPage(); 
        }
    }

    pdf.save("Admit Letter.pdf");
};

  const loaGenerate = (data: any) => {
    const date = new Date(data.createdAt).toLocaleDateString();
    const startDate =  new Date(data.loaStartDate).toLocaleDateString();
    const endDate =  new Date(data.loaEndDate).toLocaleDateString();
  
    
    return (
      <>
        <div className="main-section-loa">
          <div className="container">
            <div className="row">
              <div className="col-md-12 p-5">
                <div className="text-end">
                  <p className="mb-0">Date: {date}</p>
                </div>
                <div className="pt-2">
                  <p>
                    Dear <b>{data?.userName}</b>,
                  </p>
                  <p>
                    We are pleased to offer you a{" "}
                    <b>Project-Based Internship</b> in <b>{data?.loaCourseName}</b>{" "}
                    through
                    <b> Eduexpose</b>. The internship will run from{" "}
                    <b>{startDate}</b> to
                    <b>&nbsp;{endDate}</b>.
                  </p>
                  <p className="mt-5">
                    <b>Terms of Internship:</b>
                  </p>
                  <ol>
                    <li className="mt-4">
                      <b>Work Schedule:</b>
                      <br />
                      <p>
                        The official working hours for this internship are{" "}
                        <b>10:00 AM to 08:00 PM</b>. Flexibility is not offered,
                        and it is mandatory to complete the assigned work hours,
                        either from the office or remotely. You may occasionally
                        be required to work weekends, in which case compensatory
                        days will be arranged.
                      </p>
                    </li>
                    <li className="mt-4">
                      <b>Scope of Work:</b>
                      <br />
                      <p>
                        You are expected to execute all tasks and duties
                        assigned by Eduexpose’s management in a manner
                        consistent with the position offered to you. These tasks
                        will reflect your role in the{" "}
                        <b>{data?.loaCourseName}</b> project.
                      </p>
                    </li>
                    <li className="mt-4">
                      <b>Shift Requirements:</b>
                      <br />
                      <p>
                        Depending on operational needs, shift-based work may be
                        required. Your supervisor will provide advance notice of
                        any such arrangements.
                      </p>
                    </li>
                    <li className="mt-4">
                      <b>Confidentiality:</b>
                      <br />
                      <p>
                        You will have access to confidential and proprietary
                        information. It is imperative that this information be
                        treated with strict confidentiality, in accordance with
                        our <b>Confidentiality Agreement</b>. Sharing
                        confidential information with unauthorized individuals,
                        inside or outside of Eduexpose, is prohibited.
                      </p>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="main-section-loa mt-5">
          <div className="container">
            <div className="row">
              <div className="col-md-12 p-5 mt-4">
                <p>
                  <b>Attendance Policy:</b>
                </p>
                <p>
                  As part of this internship, attendance will be closely
                  monitored and recorded. You are required to maintain a minimum
                  attendance of 75% over the internship period to qualify for
                  certification and further recognition.
                </p>
                <ul>
                  <li>Log in daily within the prescribed working hours.</li>
                  <li>
                    Inform your supervisor of any planned absences at least 24
                    hours in advance.
                  </li>
                  <li>
                    Provide valid reasons for any unplanned absences and ensure
                    they are documented. Failure to meet the attendance
                    requirements may result in disciplinary action or withdrawal
                    of the internship offer.
                  </li>
                </ul>

                <p className="mt-5">
                  <b>Data Security Obligations:</b>
                </p>
                <ul>
                  <li>Seek written approval before disclosing any data.</li>
                  <li>
                    Access only the systems or data you are authorized to view.
                  </li>
                  <li>
                    Keep sensitive data and documentation secure, especially
                    when not in use.
                  </li>
                  <li>
                    Protect your access credentials and avoid sharing them with
                    unauthorized individuals.
                  </li>
                </ul>

                <p className="mt-5">
                  <b>Code of Conduct:</b>
                </p>
                <p>
                  During the internship, you are required to comply with the{" "}
                  <b>Eduexpose Code of Conduct</b>, which outlines our
                  expectations regarding professional behavior.
                </p>

                <p className="mt-5">
                  <b>Training:</b>
                </p>
                <p>
                  The Company may provide training relevant to your role, which
                  will be at our expense. You are expected to fully participate
                  and utilize the skills and knowledge imparted.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="main-section-loa mt-5">
          <div className="container">
            <div className="row">
              <div className="col-md-12 p-5 mt-4">
                <p>
                  <b>Background Verification:</b>
                </p>
                <p>
                  As part of our standard onboarding process, a background check
                  will be conducted. Please ensure that all required documents
                  are submitted. By signing this offer letter, you acknowledge
                  and accept the terms of this internship.
                </p>
                <p className="mt-5">
                  <b>Date:</b> ____________________
                </p>
                <p>
                  <b>Signature:</b> ____________________
                </p>
                <p>
                  <b>Name:</b> ____________________
                </p>
                <p>
                  <b>Permanent Address:</b> ____________________
                </p>

                <p className="mt-5">
                  We look forward to welcoming you to <b>Eduexpose</b> and are
                  confident that this internship will offer you valuable
                  experience.
                </p>
                <p>Best regards,</p>
                <div>
                    <img src={signImg} width="150px" alt="" />
                  </div>
                <div className="mt-3">
                  <p className="mb-0">
                    <b>Sridhar Reddy</b>
                  </p>
                  <p className="mb-0">Chief Operating Officer</p>
                  <p>Eduexpose</p>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="container-fluid ">
        <div className="row">
          <Header />
        </div>
        <div className="row">
          <div className="col-md-2 h-100 px-0">
            <Sidebar />
          </div>
          {tableData?.length > 0 ? (
            <>
              <div className="col-md-10 content-scroll content-bg mt-5 px-5">
                <table className="table">
                  <thead>
                    <tr>
                      <th style={{ color: "white" }} className="primary-bg">
                        Domain Name
                      </th>
                      <th style={{ color: "white" }} className="primary-bg">
                        Internship Period
                      </th>
                      <th style={{ color: "white" }} className="primary-bg">
                        Issue Dates
                      </th>
                      <th style={{ color: "white" }} className="primary-bg">
                        Admin Letter
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData?.map((item: any, index: number) => {
                      return (
                        <tr key={index}>
                          <td>{item.loaCourseName}</td>
                          <td>
                            {dateFormat(item.loaStartDate)} to{" "}
                            {dateFormat(item.loaEndDate)}
                          </td>
                          <td>{dateFormat(item.createdAt)}</td>
                          <td>
                            <button
                              className="bg-transparent text-black border border-black px-4 py-1 mt-2 primary-btn w-75"
                              style={{ borderRadius: "5px" }}
                              onClick={() => handleView(item)}
                            >
                              view
                            </button>
                            <br />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <>
              <div className="col-md-10 content-scroll content-bg px-5 mt-5 text-center">
                <br />
                <br />
                <br />
                <br />
                <img
                  src={certifcateImg}
                  width="30%"
                  height="50%"
                  className="mt-5"
                />
                <h4 className="">
                  <b>
                    {" "}
                    Empty <span className="primary-color">Admit Letter</span>
                  </b>
                </h4>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default LOA;
