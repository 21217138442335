import { DataTableCol } from "../../../components/tables/types";

export const LoaDataGridCols: DataTableCol[] = [
    {
        title: 'Date',
        control: 'updatedAt',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Name',
        control: 'firstName',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Email',
        control: 'email',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Batch Name',
        control: 'userBatchName',
        sortable: true,
        canShowColumn: true
    },
    {
        title: 'Start Date',
        control: 'startDate',
        canShowColumn: true
    },
    {
        title: 'End Date',
        control: 'endDate',
        canShowColumn: true
    },
    {
        title: 'Access key',
        control: 'loaAccesskey',
        canShowColumn: true
    },
    
    {
        title: 'Actions',
        control: 'both',
        sortable: false,
        isEdit: false,
        isDelete: false,
        canShowColumn: true,
        isAccessButton:true
    },
];