import React, { useEffect, useState } from "react";
import { Header } from "../../../components/user-dashboard/header";
import { Sidebar } from "../../../components/user-dashboard/sidebar";
import { useLocation,useNavigate } from "react-router-dom";
import { AdminService } from "../../../app/service/admin.service";
import { toast } from "react-toastify";

const EditAmbassador = () => {
  const location = useLocation();
  const navigate=useNavigate();
  const { state } = location;

  const [formData, setFormData] = useState({
    name: "",
  });
  useEffect(() => {
    if (state) {
      setFormData({
        name: state.name,
      });
    }
  }, [state]);


  const handleInput = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleUpdate=()=>{
    const payload = {
      ambId: state?._id,
      type:"amb",
      name:formData.name
  };
  AdminService.updateRegisterAmb(payload).then((res: any) => {
      if (res.status === "Success") {
          toast.success(res.message);
          navigate('/admin/certificatelist')
   
      } else {
          toast.error(res.message);
      }
  });
  }
  const handleCancel = () => {
    navigate("/admin/certificatelist");
  };

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <Header />
        </div>
        <div className="row">
          <div className="col-md-2 h-100 px-0">
            <Sidebar />
          </div>
          <div className="col-md-10 content-scroll content-bg px-3 py-3">
            <h4 style={{ textAlign: "left" }}>
              <b>Edit Ambassador</b>
            </h4>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group mt-3">
                  <label className="register-font">Name *</label>
                  <input
                    type="text"
                    className={`form-control mt-2`}
                    placeholder="First Name"
                    name="name"
                    value={formData.name}
                    onChange={handleInput}
                  />
                </div>
                <div className="mt-3">
              <button
                type="submit"
                className="btn btn-primary me-2"
                onClick={handleUpdate}
              >
                Update
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditAmbassador;


